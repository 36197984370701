.fit {
  min-height: calc(100vh - 88px);
}

.color-wine {
  color: #722F37;
}

.form-label {
  display: block;
  font-weight: 500;
}

.form-input,
.form-textarea {
  background-color: transparent;
  border: 1px solid #212121;
  border-radius: 0.375rem;
  display: block;
  padding: 0.5rem 1rem;
  width: 100%;
}

.form-input {
  height: 40px;
}

.form-label > .form-input,
.form-label > .form-textarea {
  margin-top: 0.25rem;
}

.card {
  background-color: theme('colors.white');
  border-radius: theme('borderRadius.lg');
  padding: theme('spacing.6');
  box-shadow: theme('boxShadow.xl');
}

.md-content h2 {
  font-size: theme('fontSize.2xl');
  margin-top: theme('spacing.4')
}

.md-content h3 {
  font-size: theme('fontSize.xl');
  margin-top: theme('spacing.3')
}

.md-content p {
  margin-top: theme('spacing.4')
}

.md-content ul,
.md-content ol {
  margin-left: theme('spacing.4');
  margin-top: theme('spacing.4');
}

.md-content ul {
  list-style: inside;;
}

.md-content ol {
  list-style: decimal;
}

.md-content ul li,
.md-content ol li {
  margin-top: theme('spacing.3')
}

.md-content a {
  color: #722F37;
}

a:hover {
  color: #722F37;
}

.md-content table {
  margin-top: theme('spacing.4');
  min-width: theme('width.full');
  text-align: left;
}

.md-content table > :not([hidden]) ~ :not([hidden]),
.md-content tbody > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  --tw-divide-opacity: 1;

  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
}

.md-content thead {
  background-color: theme('backgroundColor.gray.50');
} 

.md-content th,
.md-content td {
  padding: theme('spacing.4');
}

.md-content th {
  color: theme('colors.gray.500');
  font-size: theme('fontSize.xs');
  font-weight: theme('fontWeight.medium');
  letter-spacing: theme('letterSpacing.wider');
  text-transform: uppercase;
}

.md-content td {
  color: theme('colors.gray.900');
}

@media screen and (min-width: 768px) {
  .md-content h2 {
    font-size: theme('fontSize.3xl');
    margin-top: theme('spacing.8')
  }

  .md-content h3 {
    margin-top: theme('spacing.6')
  }
}

@media screen and (min-width: 1024px) {
  .md-content h2 {
    font-size: theme('fontSize.4xl');
  }

  .md-content h3 {
    font-size: theme('fontSize.2xl');
  }
}
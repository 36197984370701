@font-face {
  font-family: 'Josefin Sans';
  src: url('./fonts/josefine_sans/JosefinSans-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/raleway/Raleway-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/raleway/Raleway-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/raleway/Raleway-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/raleway/Raleway-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/raleway/Raleway-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/raleway/Raleway-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/raleway/Raleway-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/raleway/Raleway-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/raleway/Raleway-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/raleway/Raleway-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}
